<template>
  <div class="it">
    <backgroundcarrerpage :career="career_data"/>
    <navbar/>
    
    <work-page :career="career_data"/>
    <br/>
  </div>
</template>

<script>
import backgroundcarrerpage from '@/components/backgroundcarrerpage.vue'
import Navbar from '../components/Navbar.vue'
import WorkPage from '../components/workPage.vue'
import Data from './../data'

export default {
  name: 'Career.page',
  components: {
    backgroundcarrerpage,
    Navbar,
    WorkPage, 
    
  },
  data() {
      return {
        careers: Data.careers,
        career_data:''
      }
    },
  created() {
    this.career_data=this.careers[this.$route.params.id-1]
  },
}
</script>