<template>
  <div class="career-container">
    <div style="padding:20px 0 10px 0;" class="center">
        <h4 class="approach-above-main-title">WORK WITH OUR TEAM</h4>
    </div>
    <div class="center">
      <h1>{{ career.name }}</h1>
      <v-row>
        <v-col cols="12" md="7" lg="8" class="left-div">
          <h2 style="margin:20px 0; color:#77C74E;">Job Description</h2>
          <p>{{ career.description }}</p><br/>

          <h2 style="margin-bottom:20px; color:#77C74E;">Job Responsibilities</h2>
          <ul>
            <li
              v-for="(item, i) in career.responsibilities"
              :key="i"
            >
              <p v-text="item"></p>
            </li>
          </ul><br/>

          <h2 style="margin-bottom:20px; color:#77C74E;">Job Requirements</h2>
          <ul>
            <li
              v-for="(item, i) in career.requirements"
              :key="i"
            >
              <p v-text="item"></p>
            </li>
          </ul>
        </v-col>

        <v-col cols="12" md="5" lg="4">
          <v-card class="apply-form-card">
            <v-card-title>Apply Now</v-card-title>
            <v-card-text>
              <v-form>
                Your Name
                <v-text-field
                  placeholder="Alish Dahal"
                >
                </v-text-field>
                Your Email
                <v-text-field
                  placeholder="example@gmail.com"
                >
                </v-text-field>
                Your CV*
                <v-file-input
                  label="Choose"
                ></v-file-input>

                <v-btn color="#77C74E" dark depressed>
                  Apply
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

export default {
  data() {
      return {
        search:'',
      }
  },
  props: {
    career: {
      type: Object,
      required: true,
    },
  },
}
</script>


<style scoped>
.approach-above-main-title{
  text-align:center;
  color:#ABABAC;
  letter-spacing: 3px;
  margin-top:10px;
}
.approach-main-title{
  text-align:center;
  font-weight: 600;
  font-size:40px;
}
.apply-form-card{
  border-radius:20px;
  padding:10px;
  margin-left:20px;
}
.left-div{
  border-right: 2px solid #e3e3e3;
  padding-right: 20px;
}

/* --------- Responsive ---------- */

@media only screen and (max-width: 400px) {
  .left-div{
    border-right: none;
  }
}
@media only screen and (min-width: 400px) {
  .left-div{
    border-right: none;
  }
}
@media only screen and (min-width: 600px) {
  .left-div{
    border-right: none;
  }
}
@media only screen and (min-width: 768px) {
  .left-div{
    border-right: none;
  }
}
@media only screen and (min-width: 880px) {
  .left-div{
    border-right: none;
  }
}
@media only screen and (min-width: 992px) {
  .left-div{
    border-right: 2px solid #e3e3e3;
  }
}
@media only screen and (min-width: 1200px) {
  .left-div{
    border-right: 2px solid #e3e3e3;
  }
}
@media only screen and (min-width: 1300px) {
  .left-div{
    border-right: 2px solid #e3e3e3;
  }
}
@media only screen and (min-width: 1400px) {
  .left-div{
    border-right: 2px solid #e3e3e3;
  }
}
</style>
